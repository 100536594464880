import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Scoped, k } from 'kremling';
import copy from 'copy-to-clipboard';
import { getCoupons, deleteCoupon } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { ModalContext } from 'context/modal-context';
import { CouponModal } from './coupon-modal.component';
import { CouponCreateBatchModal } from './coupon-create-batch-modal.component';
import { CouponDownloadBatchModal } from './coupon-download-batch-modal.component';
import { Icon, PageHeaderMui, toasterService } from 'components';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { IconButton, Tooltip, Box, Stack } from '@mui/material';
import { DownloadIcon, DuplicateIcon, DeleteIcon, EditIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
export const CouponsList = () => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const {
    confirm,
    show
  } = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);
  const [createBatchCoupon, setCreateBatchCoupon] = useState();
  const [downloadBatchCoupon, setDownloadBatchCoupon] = useState();
  const [renderIteration, setRenderIteration] = useState(0);
  const copyId = id => {
    if (copy(id)) {
      toasterService.success(`Coupon ID ${id} copied to clipboard`);
    }
  };
  const couponModal = coupon => {
    show(CouponModal, {
      coupon
    }).then(() => {
      setRenderIteration(r => r + 1);
    }).catch(() => {});
  };
  const confirmDelete = coupon => {
    confirm({
      title: 'Delete Coupon',
      message: `Are you sure you want to delete the coupon, "${coupon.promo_code}"?  This action cannot be undone.`,
      submitText: 'Delete'
    }).then(() => {
      return deleteCoupon(coupon.id).then(() => {
        setRenderIteration(r => r + 1);
      });
    }).catch(() => {});
  };
  const headCells = [{
    key: 'id',
    label: 'ID',
    width: '25%',
    displayComponent: ({
      data: coupon
    }) => <> {coupon?.id || ''}</>
  }, {
    key: 'name',
    label: 'Name',
    width: '25%',
    displayComponent: ({
      data: coupon
    }) => <> {coupon?.name || ''}</>
  }, {
    key: 'created_when',
    label: 'Created',
    width: '4%',
    displayComponent: ({
      data: coupon
    }) => <> {moment(coupon?.created_when).format('MMM D, YYYY') || ''}</>
  }, {
    key: 'discount_type',
    label: 'Discount Type',
    width: '8%',
    displayComponent: ({
      data: coupon
    }) => <>
          {coupon.discount_type === 'amount_off' && '$ OFF' || coupon.discount_type === 'percent_off' && '% OFF' || coupon.discount_type === 'sale_price' && 'Sale Price'}
        </>
  }, {
    key: 'discount_amount',
    label: 'Discount Amount',
    width: '8%',
    displayComponent: ({
      data: coupon
    }) => <> {coupon?.discount_amount || ''} </>
  }, {
    key: 'promo_code',
    label: 'Promo Code',
    width: '10%',
    displayComponent: ({
      data: coupon
    }) => coupon?.promo_code || ''
  }, {
    key: 'redeemed',
    label: 'Redeemed / Assigned / Unassigned',
    width: '10%',
    noSort: true,
    displayComponent: ({
      data: coupon
    }) => <>
          {coupon.redeemed} / {coupon.assigned} / {coupon.unassigned}
        </>
  }];

  /*
  goToCreateCoupon() {
    this.props.history.push({
      pathname: '/create-coupon',
      state: { copy: false, edit: false },
    });
  }
   isCouponSelected(id) {
    this.setState({ isSelected: id, openModal: true });
  }
   isCouponEdited(id) {
    this.setState({ isSelected: id });
    this.props.history.push({
      pathname: `/coupons/${id}/edit`,
      state: { edit: true },
    });
  }
   isCouponCopied(id) {
    this.setState({ isSelected: id });
    this.props.history.push({
      pathname: `/coupons/${id}/copy`,
      state: { copy: true },
    });
  }
   deleteCoupon() {
    deleteCoupon(this.state.isSelected).then(({ data }) => {
      this.setState({ openModal: false });
      window.location.reload();
    });
  }
   goToCoupon = (id) => {
    this.props.history.push(`/coupons/${id}`);
  };
   updateOrdering = (ordering) => {
    this.setState({ ordering, currentPage: 1 }, () => this.showCoupons());
  };
  */

  return <Scoped css={css}>
      <div className="wrapper">
        <PageHeaderMui type="Coupons" icon={<Icon name="custom-sell" size={34} />} />
        <EnhancedTable type="Coupons" headCells={headCells} api={getCoupons} defaultSort={'-updated_when'} handleRowClick={coupon => copyId(coupon.id)} renderIteration={renderIteration} actions={hasPermission('coupon.add_coupon') && <NewButton onClick={e => couponModal()} />} hoverActions={({
        data
      }) => <Box display="flex" justifyContent="flex-end">
              <Stack direction="row">
                {hasPermission('coupon.delete_coupon') && <Tooltip title="Delete Coupon">
                    <IconButton onClick={e => {
              e.stopPropagation();
              confirmDelete(data);
            }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('coupon.change_coupon') && <Tooltip title="Edit Coupon">
                    <IconButton onClick={e => {
              e.stopPropagation();
              couponModal(data);
            }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('coupon.add_coupon') && <Tooltip title="Create Batch">
                    <IconButton onClick={e => {
              e.stopPropagation();
              setCreateBatchCoupon(data);
            }}>
                      <DuplicateIcon />
                    </IconButton>
                  </Tooltip>}
                <Tooltip title="Download Batch">
                  <IconButton onClick={e => {
              e.stopPropagation();
              setDownloadBatchCoupon(data);
            }}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>} />
      </div>
      <CouponCreateBatchModal onClose={() => setCreateBatchCoupon()} onSubmit={() => {
      setDownloadBatchCoupon(createBatchCoupon);
      setRenderIteration(r => r + 1);
    }} coupon={createBatchCoupon} open={!!createBatchCoupon} />
      <CouponDownloadBatchModal open={!!downloadBatchCoupon} coupon={downloadBatchCoupon} onClose={() => setDownloadBatchCoupon()} />
    </Scoped>;
};
const css = {
  styles: `[kremling="i11"] body,body[kremling="i11"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i11"] .coupons-list,[kremling="i11"].coupons-list {
  padding: 2rem;
}

[kremling="i11"] .coupons-list table button,[kremling="i11"].coupons-list table button {
  opacity: 0;
}
[kremling="i11"] .coupons-list table tr:hover button,[kremling="i11"].coupons-list table tr:hover button {
  opacity: 1;
}

[kremling="i11"] .coupon-dropdown,[kremling="i11"].coupon-dropdown {
  width: 100px !important;
}

[kremling="i11"] .invalid-input,[kremling="i11"].invalid-input {
  margin-bottom: 0px;
  color: red;
}`,
  id: 'i11',
  namespace: 'kremling'
};